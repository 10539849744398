.app-footer {
    width: 100%;             /* Asegura que el footer ocupe todo el ancho */
    background-color: #ffffff;  /* Color de fondo */
    color: rgb(0, 0, 0);            /* Color del texto */
    padding: 20px;           /* Espaciado interno */
    text-align: center;      /* Centra el texto */
    margin-top: auto;        /* Empuja el footer hacia abajo si el contenido es pequeño */
    position: relative;      /* Asegura que se mantenga en la parte inferior */
  }
  
  .app-footer .social-media a {
    margin-right: 20px;      /* Espacio entre los íconos */
  }
  
  .social-icon {
    width: 30px; /* Ajusta el tamaño de los íconos */
    height: 30px; /* Mantiene la proporción */
    transition: transform 0.3s ease; /* Efecto de hover */
  }
  
  .social-icon:hover {
    transform: scale(1.1); /* Efecto de agrandado al pasar el cursor */
  }
  
  