table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #bb2c2c; /* Color de fondo actualizado */
  color: white; /* Color de texto blanco para mayor contraste */
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #e9e9e9;
}

.filters {
  display: flex;
  justify-content: flex-start; /* Alinea los filtros a la izquierda */
  margin-bottom: 20px;
  flex-wrap: wrap; /* Los filtros se acomoden en varias filas */
  gap: 15px; /* Espaciado entre los filtros */
}

.filters input {
  margin-bottom: 10px;
  width: 100%; /* Hacer que los inputs ocupen el ancho completo de su contenedor */
  max-width: 250px; /* Limitar el tamaño de los inputs */
  padding: 10px; /* Mejorar la apariencia con padding */
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Estilos para el placeholder de los inputs */
.input-field::placeholder {
  color: #94a3b8;
}

/* Estilos para cuando el input está enfocado o pasa el mouse por encima */
.input-field:focus,
.input-field:hover {
  outline: none;
  border-color: #bb2c2c;
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
}

.input-field {
  height: 45px;
  padding-left: 10px;
  color: #333;
}

/* Ajustes para dispositivos móviles o pantallas pequeñas */
@media (max-width: 768px) {
  .filters {
    justify-content: center; /* Centra los filtros en pantallas pequeñas */
  }

  .filters input {
    width: 100%;
  }
}

.icon-container {
  display: flex;
  gap: 10px; /* Ajusta el valor del gap para separar los iconos */
}


.icon-button {
  cursor: pointer;
  width: 24px; /* Ajusta según el tamaño que necesites */
  height: 24px;
  transition: transform 0.2s ease;
}

.icon-button:hover {
  transform: scale(1.1); /* Efecto de hover */
}

.button-save, .button-cancel {
  margin-right: 20px; /* Ajusta el valor según la separación que desees */
}

.button-save, .button-cancel {
  padding: 8px 16px;
  cursor: pointer;
}

table {
  user-select: none;
}

th, td {
  user-select: none;
}

.message-label {
  color: #bb2c2c; /* Color rojo para resaltar el mensaje */
  font-size: 14px; /* Tamaño de fuente ajustado */
  margin-top: 10px; /* Ajusta el margen para posicionarlo correctamente */
  text-align: left; /* Alineación a la izquierda */
  max-width: 400px; /* Limita el ancho máximo */
}
