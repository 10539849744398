.app-header {
  width: 100%;
  background-color: #ffffff  /* Fondo rojo oscuro forzado */;
  color: rgb(236, 16, 16);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinea la imagen a la izquierda */
}

.logo {
  height: 200px; /* Ajusta el tamaño de la imagen */
  width: auto;
  margin-right: 20px; /* Añade espacio entre la imagen y los otros elementos */
}


nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin-right: 20px;
}

/* Contenedor para el label y el botón */
.auth-container {
  display: flex;
  align-items: center;
}

/* Estilo del label */
.auth-message {
  font-size: 16px;
  color: #333;
  margin-right: 10px; /* Espacio entre el label y el botón */
}

/* From Uiverse.io by gagan-gv */ 
.button {
  padding: 15px 20px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  z-index: 1;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
}




.button::after,
.button::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}
