
/* Contenedor principal centrado */
.App {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Alinea la imagen a la izquierda */
  }
  
  .logo {
    height: 50px; /* Ajusta el tamaño de la imagen */
    width: 50px;
    margin-right: 20px; /* Añade espacio entre la imagen y los otros elementos */
  }

/* Contenedor del formulario dividido en dos columnas */
.login-container {
    display: flex;
    width: 100%;
    max-width: 800px; /* Ancho máximo del contenedor */
    margin: auto;
    padding: 20px;
    /* Color de la sombra del container*/
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.26);
   
    border-radius: 8px;
    background-color: #5e5a5a1e;
}

/* Columna izquierda para los campos de entrada */
.form-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    padding-right: 20px;
}

/* Alineación de etiquetas y campos de entrada */
.form-left label {
    margin-bottom: 2px;
    /*font-weight: bold;*/
   text-align: left;
    width: 100%;
}

/* Estilo para los inputs */
.form-left .input-field {
    width: 100%;
    height: 45px;
    line-height: 30px;
    padding: 0 5rem;
    padding-left: 3rem;
    border: 2px solid transparent;
    border-radius: 10px;
    outline: none;
    background-color: #ffffff;
    color: #0d0c22;
    transition: .5s ease;
    margin-bottom: 15px;
    max-width: 400px;
    box-sizing: border-box;
}

/* Estilos para el placeholder de los inputs */
.form-left .input-field::placeholder {
    color: #94a3b8;
}

/* Estilos para cuando el input está enfocado o pasa el mouse por encima */
.form-left .input-field:focus,
.form-left .input-field:hover {
    outline: none;
    border-color: #bb2c2c;
    background-color: #fff;
    box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
}

/* Columna derecha para la imagen */
.form-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-image {
    width: 100%;
    max-width: 300px; /* Ajusta el tamaño máximo de la imagen */
    height: auto;
    border-radius: 10px;
}

/* Estilo para el botón de envío */
button {
    padding: 15px 20px;
    background-color: #bb2c2c;
    color: white;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
  }

/* Efecto hover para el botón */
button:hover {
    background-color: #000000;
}

/* Ajustes adicionales para centrar el formulario */
form {
    width: 100%;
    max-width: 800px;
}

/* Asegura que los elementos dentro de form-left estén alineados a la izquierda */
.form-left div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

/* Estilos adicionales para un icono dentro del input (si decides agregarlo) */
.form-left .group {
    display: flex;
    line-height: 30px;
    align-items: center;
    position: relative;
    max-width: 400px; /* Ajusta el max-width según sea necesario */
}

/* Estilo para el icono (si lo añades dentro del input) */
.form-left .icon {
    position: absolute;
    left: 1rem;
    fill: none;
    width: 1rem;
    height: 1rem;
}

/* Estilos para el enlace de 'Olvidó su contraseña' */
.forgot-password {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px; /* Ajusta según lo necesites */
    position: relative; /* Asegura que no haya interferencias */
  }
  
  .forgot-password a {
    font-size: 16px;
    color: #bb2c2c;
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
    color: #000000;
  }
  
  .message-label {
    color: #bb2c2c; /* Color rojo para resaltar el mensaje */
    font-size: 14px; /* Tamaño de fuente ajustado */
    margin-top: 10px; /* Ajusta el margen para posicionarlo correctamente */
    text-align: left; /* Alineación a la izquierda */
    max-width: 400px; /* Limita el ancho máximo */
}

  
 
/* Styles for the switch */
.toggler {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;
    margin-top: -5px; /* Ajusta este valor según lo que necesites */
 }
  
  .toggler input {
    display: none;
    margin-right: 18px; 
  }
  
  .toggler label {
    display: block;
    position: relative;
    width: 72px;
    height: 36px;
    border: 1px solid #d6d6d6;
    border-radius: 36px;
    background: #e4e8e8;
    cursor: pointer;
    margin-left: 8px; 
    
  }
  
  .toggler label::after {
    display: block;
    border-radius: 100%;
    background-color: #bb2c2c;
    content: ' ';
    animation-name: toggler-size;
    animation-duration: 0.15s;
    animation-timing-function: ease-out;
    animation-direction: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
  }
  
  .toggler label::after, .toggler label .toggler-on, .toggler label .toggler-off {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 26px;
    height: 26px;
    transform: translateY(-50%) translateX(-50%);
    transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out;
  }
  
  .toggler input:checked + label::after, .toggler input:checked + label .toggler-on, .toggler input:checked + label .toggler-off {
    left: 75%;
  }
  
  .toggler input:checked + label::after {
    background-color: #50ac5d;
    animation-name: toggler-size2;
  }
  
  .toggler .toggler-on, .toggler .toggler-off {
    opacity: 1;
    z-index: 2;
  }
  
  .toggler input:checked + label .toggler-off, .toggler input:not(:checked) + label .toggler-on {
    width: 0;
    height: 0;
    opacity: 0;
  }
  
  .toggler .path {
    fill: none;
    stroke: #fefefe;
    stroke-width: 7px;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
  
  @keyframes toggler-size {
    0%, 100% {
      width: 26px;
      height: 26px;
    }
  
    50% {
      width: 20px;
      height: 20px;
    }
  }
  
  @keyframes toggler-size2 {
    0%, 100% {
      width: 26px;
      height: 26px;
    }
  
    50% {
      width: 20px;
      height: 20px;
    }
  }
  
 /* Contenedor del checkbox con el texto */
.checkbox-container {
  display: flex;
  align-items: center; /* Centra verticalmente el checkbox y el texto */
  gap: 10px; /* Espacio entre el checkbox y el texto */
  margin-top: 15px; /* Espacio superior */
  flex-direction: row-reverse; /* Esto pone el checkbox al final del texto */
}

.checkbox-container input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen extra */
  width: 18px; /* Ajusta el tamaño del checkbox */
  height: 18px; /* Ajusta el tamaño del checkbox */
}

.checkbox-container label {
  font-size: 14px; /* Tamaño de la fuente */
  line-height: 1.5; /* Espaciado adecuado entre líneas */
  text-align: left; /* Alinea el texto a la izquierda */
  margin: 0; /* Elimina márgenes no deseados */
  display: inline; /* Asegura que el texto esté en línea con el checkbox */
}

/* Estilos de los enlaces dentro del checkbox */
.checkbox-container a {
  color: #bb2c2c; /* Color de los enlaces */
  text-decoration: none; /* Sin subrayado */
}

.checkbox-container a:hover {
  text-decoration: underline; /* Subrayado al pasar el ratón */
}

